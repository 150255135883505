<button (click)="handleClick()" [class.active]="isActive" aria-label="arrow link" class="arrow-link">
  <div class="square-container">
    <div class="circle-container">
      <img [src]="circleSvg" alt="" aria-hidden="true" class="circle" />
      <img [src]="semiCircleSvg" alt="" aria-hidden="true" class="semi-circle" />
    </div>

    <img [src]="arrowActive" alt="" aria-hidden="true" class="arrow arrow-active" />
    <img [src]="arrowInactive" alt="" aria-hidden="true" class="arrow arrow-inactive" />
  </div>
</button>
