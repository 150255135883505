import { Component, Input, OnInit } from '@angular/core';

type SHAPE = 'square' | 'tall' | 'short';
type POSITION = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
type COLOR = undefined | 'flour' | 'slate' | 'slate-light';

@Component({
  selector: 'app-img-with-backdrop',
  templateUrl: './image-with-backdrop.component.html',
  styleUrls: ['./image-with-backdrop.component.scss']
})
export class ImageWithBackdropComponent implements OnInit {
  @Input() src = '/assets/placeholder.png';
  @Input() alt = '';
  @Input() rounded = false;
  @Input() shape: SHAPE = 'square';
  @Input() backdropPos: POSITION = 'top-right';
  @Input() backdropColor: COLOR;
  backdropSrc = '';

  ngOnInit(): void {
    if (this.backdropColor) this.backdropSrc = `/assets/patterns/pattern-fill-${this.backdropColor}.svg`;
  }

  getForegroundPosClassName(): POSITION {
    switch (this.backdropPos) {
      case 'top-left':
        return 'bottom-right';
      case 'top-right':
        return 'bottom-left';
      case 'bottom-left':
        return 'top-right';
      case 'bottom-right':
        return 'top-left';
    }

    return 'top-right';
  }
}
