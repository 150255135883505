<div [class.rounded]="rounded" [ngClass]="['img-with-backdrop', shape]">
  <img [alt]="alt" [ngClass]="['img-foreground', getForegroundPosClassName()]" [src]="src" loading="lazy" />

  <img
    *ngIf="backdropSrc"
    [ngClass]="['img-backdrop', backdropPos]"
    [src]="backdropSrc"
    alt="image background pattern"
    aria-hidden="true"
    loading="lazy" />
</div>
