import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

const ARROW_SVG_LIGHT_PATH = '/assets/icons/icon-arrow-light.svg';
const ARROW_SVG_DARK_PATH = '/assets/icons/icon-arrow-dark.svg';

type ARROW_COLOR = undefined | 'light' | 'dark';

@Component({
  selector: 'app-arrow-link',
  templateUrl: './arrow-link.component.html',
  styleUrls: ['./arrow-link.component.scss']
})
export class ArrowLinkComponent implements OnInit {
  @Input() href = '';
  @Input() color: ARROW_COLOR;
  size = 40; // Should stay in sync with --button-size property in the component stylesheet
  isActive = false;
  circleSvg = '/assets/icons/icon-arrow-link-circle.svg';
  semiCircleSvg = '/assets/icons/icon-arrow-link-semi-circle.svg';
  arrowActive: string = ARROW_SVG_LIGHT_PATH;
  arrowInactive: string = ARROW_SVG_DARK_PATH;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.setArrowColor(this.color);
  }

  handleClick(): void {
    if (!this.href) {
      return;
    }

    if (this.isExternalLink()) {
      // If it's an external link, open it in a new tab
      window.open(this.href, '_blank');
      return;
    }

    this.router.navigate([this.href]).then();
  }

  setArrowColor(color: ARROW_COLOR = undefined): void {
    if (color === 'dark') {
      this.arrowInactive = ARROW_SVG_DARK_PATH;
      return;
    }
    this.arrowInactive = ARROW_SVG_LIGHT_PATH;
  }

  private isExternalLink(): boolean {
    // Check if the link is an internal route (starts with '/')
    return this.href.length > 0 && !this.href.startsWith('/');
  }
}
