import { Component, Input, OnInit } from '@angular/core';

type HY_CITE_LOGO_COLOR = 'flour' | 'slate' | 'tangerine' | 'tangerine-flour';

@Component({
  selector: 'app-hy-cite-logo',
  templateUrl: './hy-cite-logo.component.html',
  styleUrls: ['./hy-cite-logo.component.scss']
})
export class HyCiteLogoComponent implements OnInit {
  @Input() color: HY_CITE_LOGO_COLOR = 'tangerine';
  @Input() withWords = false;
  logoSrc = '/assets/hy-cite-logos/logo-hy-cite-tangerine.svg';

  ngOnInit(): void {
    this.setLogoSrc();
  }

  private setLogoSrc(): void {
    this.logoSrc = `/assets/hy-cite-logos/logo-hy-cite-${this.color}${this.withWords ? '-words' : ''}.svg`;
  }
}
