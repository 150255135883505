import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowLinkComponent } from './arrow-link.component';

@NgModule({
  declarations: [ArrowLinkComponent],
  exports: [ArrowLinkComponent],
  imports: [CommonModule]
})
export class ArrowLinkModule {}
