import { Component, OnInit } from '@angular/core';
import { HeaderColorService } from './services/header-color.service';
import { filter, pluck } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Params, Router, UrlTree } from '@angular/router';
import { FooterCTAService } from './services/footer-cta.service';
import { FailedEvent, LangChangedEvent, LoadedEvent, TranslocoService } from '@ngneat/transloco';
import { Angulartics2GoogleAnalytics, Angulartics2GoogleTagManager } from 'angulartics2';

declare let window: any;
declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hycite-website';

  constructor(
    private router: Router,
    private headerColorService: HeaderColorService,
    private footerCTAService: FooterCTAService,
    private translocoService: TranslocoService,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) {
    angulartics2GoogleTagManager.startTracking();
  }

  ngOnInit(): void {
    // Subscribe to route changes
    this.router.events.pipe(filter((event: any): boolean => event && event instanceof NavigationEnd)).subscribe((event): void => {
      // Reset header appearance
      this.headerColorService.callHeaderMethod('slate');

      // Reset footer appearance
      this.footerCTAService.callFooterMethod(true);

      this.reloadOTBanner();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      gtag('event', 'page_view', {
        page_location: document.location.origin + event.urlAfterRedirects,
        page_title: document.title
      });

      // Get language selection from URL query parameters and set the Transloco language accordingly.
      const newUrl = event.urlAfterRedirects;
      const tree: UrlTree = this.router.parseUrl(newUrl);
      const queryParams: Params = tree.queryParams;
      let lang = queryParams['lang'];

      if (lang) {
        // if (lang.includes('por') || lang.includes('pt')) {
        //   lang = 'es'; // Portuguese will be treated as Spanish until the Portuguese translation is copied in.
        // }

        this.translocoService.setActiveLang(lang);
        localStorage.setItem('HYCITE-SELECTED-LANG', lang);
      }
    });

    // Subscribe to language changes from Transloco to updated localStorage
    this.translocoService.events$
      .pipe(
        filter((e: LoadedEvent | FailedEvent | LangChangedEvent): boolean => e.type === 'langChanged'),
        pluck('payload')
      )
      .subscribe((e: any): void => {
        const prevLangSelection: string = localStorage.getItem('HYCITE-SELECTED-LANG') ?? 'en';
        const langSelection: string = e?.langName ?? 'en';

        if (langSelection !== prevLangSelection) {
          localStorage.setItem('HYCITE-SELECTED-LANG', langSelection);
        }
      });

    // Retrieve cached language from localStorage, then set the active language with Transloco
    const cachedLangSelection: string = localStorage.getItem('HYCITE-SELECTED-LANG') ?? 'en';
    this.translocoService.setActiveLang(cachedLangSelection);
  }

  getCookie(name: any) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length == 2) {
      return true;
    }
    return false;
  }

  reloadOTBanner() {
    const otConsentSdk = document.getElementById('onetrust-consent-sdk');
    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if (window.OneTrust != null) {
      window.OneTrust.Init();

      setTimeout(function () {
        window.OneTrust.LoadBanner();
        // console.log('onetrust loading');

        const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

        for (let i = 0; i < toggleDisplay.length; i++) {
          (toggleDisplay[i] as any).onclick = function (event: any) {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          };
        }
      }, 1000);
    }
  }
}
