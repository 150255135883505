import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ButtonModule } from '../button/button.module';
import { TranslocoModule } from '@ngneat/transloco';
import { HyCiteLogoModule } from '../hy-cite-logo/hy-cite-logo.module';

@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [CommonModule, ButtonModule, TranslocoModule, HyCiteLogoModule]
})
export class FooterModule {}
