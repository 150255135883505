import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NavLinkModule } from '../nav-link/nav-link.module';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from '../button/button.module';
import { ArrowLinkModule } from '../arrow-link/arrow-link.module';
import { TranslocoModule } from '@ngneat/transloco';
import { HyCiteLogoModule } from '../hy-cite-logo/hy-cite-logo.module';
import { ImageWithBackdropModule } from '../image/image-with-backdrop.module';
import { RouterLink } from '@angular/router';
import { HamburgerModule } from '../hamburger/hamburger.module';

@NgModule({
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    NgOptimizedImage,
    NavLinkModule,
    MatIconModule,
    ButtonModule,
    ArrowLinkModule,
    TranslocoModule,
    HyCiteLogoModule,
    ImageWithBackdropModule,
    RouterLink,
    HamburgerModule
  ]
})
export class HeaderModule {}
