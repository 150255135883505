import { Component, Input } from '@angular/core';

type HamburgerColor = 'dark' | 'light';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent {
  @Input() isActive = false;
  @Input() color: HamburgerColor = 'light';
}
