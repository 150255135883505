import { Component, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FooterCTAService } from '../../services/footer-cta.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('text1') text1Element!: ElementRef;
  @ViewChild('text2') text2Element!: ElementRef;

  text1InView = false;
  text2InView = false;
  showCTA = true;
  CTAButtonText: string | undefined;
  CTAButtonHref: string | undefined;
  copyrightText = '©2023 Hy Cite Enterprises, LLC';
  headquartersAddress: string = '3252 Pleasant View Road,<br>' + 'Middleton, WI 53562, U.S.A.';
  socialLinks: { 'img-uri': string; altText: string; url: string }[] = [
    {
      url: 'https://www.linkedin.com/company/hy-cite-corporation/',
      altText: 'Social media page: linkedin',
      'img-uri': '/assets/icons/icon-social-logo-linkedin.svg'
    },
    {
      url: 'https://www.facebook.com/hyciteenterprises/',
      altText: 'Social media page: facebook',
      'img-uri': '/assets/icons/icon-social-logo-facebook.svg'
    },
    {
      url: 'https://twitter.com/RoyalPrestige',
      altText: 'Social media page: twitter',
      'img-uri': '/assets/icons/icon-social-logo-twitter.svg'
    },
    {
      url: 'https://www.instagram.com/royalprestigeofficial/',
      altText: 'Social media page: instagram',
      'img-uri': '/assets/icons/icon-social-logo-insta.svg'
    },
    {
      url: 'https://www.youtube.com/@RoyalPrestigeOfficial',
      altText: 'Social media page: youtube',
      'img-uri': '/assets/icons/icon-social-logo-youtube.svg'
    }
  ];

  private text1!: HTMLElement;
  private text2!: HTMLElement;
  private textInViewObserver1!: IntersectionObserver;
  private textInViewObserver2!: IntersectionObserver;

  constructor(private footerCTAService: FooterCTAService, private translocoService: TranslocoService) {
    this.text1Element = { nativeElement: {} } as ElementRef;
    this.text2Element = { nativeElement: {} } as ElementRef;
    this.textInViewObserver1 = new IntersectionObserver((): void => {});
    this.textInViewObserver2 = new IntersectionObserver((): void => {});
  }

  setCTAVisibility(showCTA: boolean): void {
    this.showCTA = showCTA;
  }

  ngOnInit(): void {
    this.footerCTAService.footerMethodCalled$.subscribe((args: any[]): void => {
      const [showCTA, buttonMode] = args;
      this.setCTAVisibility(showCTA);

      switch (buttonMode) {
        case 'careers':
          this.CTAButtonText = this.translocoService.translate('pages.careers.our-employees.button');
          this.CTAButtonHref = 'https://career4.successfactors.com/career?company=hyciteente';
          break;
        case '':
        default:
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.showCTA) {
      return;
    }

    const timer: NodeJS.Timer = setInterval((): void => {
      if (this.assignTextNativeElements()) {
        this.initializeTextIntersectionObservers();

        clearInterval(timer);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.textInViewObserver1.disconnect();
    this.textInViewObserver2.disconnect();
  }

  /**
   * Assigns `text1` and `text2` to the HTMLElements belonging to the ElementRefs from the Angular template.
   * @private
   */
  private assignTextNativeElements(): boolean {
    if (this.text1Element?.nativeElement && this.text2Element?.nativeElement) {
      this.text1 = this.text1Element.nativeElement as HTMLElement;
      this.text2 = this.text2Element.nativeElement as HTMLElement;
      return true;
    }

    return false;
  }

  /**
   * Initializes two IntersectionObservers that detect whether the text1 and text2 elements are visible.
   * @private
   */
  private initializeTextIntersectionObservers(): void {
    // Create an Intersection Observer to detect when text1 enters or exits the viewport
    this.textInViewObserver1 = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
      this.text1InView = entries[0].isIntersecting;
    });

    // Create an Intersection Observer to detect when text2 enters or exits the viewport
    this.textInViewObserver2 = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
      this.text2InView = entries[0].isIntersecting;
    });

    // Start observing the two title elements
    this.textInViewObserver1.observe(this.text1);
    this.textInViewObserver2.observe(this.text2);
  }
}
