import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  transform(value: any, arguments_?: any): any {
    if (!value) {
      return value;
    }
    return Object.keys(value);
  }
}
