import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: any) {
    if (!data) {
      return data;
    }
    if (typeof data !== 'string') {
      return data;
    }
    data = data.replace(/&lt;/g, '<');
    data = data.replace(/&gt;/g, '>');
    data = data.replace(/&amp;/g, '&');
    data = data.replace(/&quot;/g, "'");
    return this.sanitizer.bypassSecurityTrustHtml(data);
  }
}

@Pipe({ name: 'html' })
export class HtmlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any, type?: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(value: string, replaceValue: string): string {
    if (value) {
      return value.replace(value, replaceValue);
    }
    return '';
  }
}
