import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ImageWithBackdropComponent } from './image-with-backdrop.component';

@NgModule({
  declarations: [ImageWithBackdropComponent],
  exports: [ImageWithBackdropComponent],
  imports: [CommonModule, NgOptimizedImage]
})
export class ImageWithBackdropModule {}
