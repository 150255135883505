import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HeaderColorService } from '../../services/header-color.service';
import { filter, map, Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';

type HeaderColor = 'slate' | 'flour' | 'white';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  color: HeaderColor = 'slate';
  isExpanded = false;
  lastSelection: any = null;
  activeRoute: undefined | string;
  selectedLanguage = 'en';
  mainLinksLeft: string[] = ['for-business', 'distributors', 'royal-prestige', 'news'];
  mainLinksRight: string[] = ['contact'];
  enabledLanguages: string[] = ['en', 'es', 'pt'];
  isSmallScreen$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 1350px)') // Width must be kept in sync with CSS media queries
    .pipe(map((result: BreakpointState) => result.matches));

  constructor(
    private translocoService: TranslocoService,
    private headerColorService: HeaderColorService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.headerColorService.headerMethodCalled$.subscribe((color: HeaderColor): void => {
      this.setHeaderColor(color);
    });

    this.router.events
      .pipe(filter((routerEvent: any): boolean => routerEvent instanceof NavigationEnd))
      .subscribe((navigationEndEvent: any): void => {
        this.activeRoute = navigationEndEvent['urlAfterRedirects'].replace('/', '');
      });

    // Retrieve cached language from localStorage, then set the active language with Transloco
    this.selectedLanguage = localStorage.getItem('HYCITE-SELECTED-LANG') ?? 'en';
  }

  setHeaderColor(color: HeaderColor): void {
    this.color = color;
  }

  handleNavItemClick(element: any = null): void {
    if (element === 'aboutMobile') {
      if (this.lastSelection === 'aboutMobile') {
        this.lastSelection = 'about';
        return;
      }

      this.lastSelection = 'aboutMobile';
      return;
    }

    if (!element) {
      this.isExpanded = false;
      this.lastSelection = null;
      return;
    }

    if ((element && this.lastSelection === element) || this.lastSelection === 'aboutMobile' || !this.isExpanded) {
      if (this.isExpanded) {
        this.lastSelection = null;
      }

      this.isExpanded = !this.isExpanded;
    }

    this.lastSelection = element;
  }

  handleLanguageSelection(lng = 'en'): void {
    this.selectedLanguage = lng;
    try {
      this.translocoService.setActiveLang(lng);
      this.handleNavItemClick();

      // Set url `lang` query parameter to `lng` and reload the page.
      const currentUrl: URL = new URL(window.location.href);
      currentUrl.searchParams.set('lang', lng);
      window.location.href = currentUrl.toString();
    } catch (e) {
      console.log('handleLanguageSelection(): error', e);
    }
  }

  handleHamburgerClick(element: any): void {
    this.handleNavItemClick(element);
  }
}
