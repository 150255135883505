import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

const ARROW_SVG_LIGHT_PATH = '/assets/icons/icon-arrow-light.svg';
const ARROW_SVG_DARK_PATH = '/assets/icons/icon-arrow-dark.svg';

type BUTTON_SIZE = 'big' | 'small';
type BUTTON_TYPE = 'fill' | 'outline';
type BUTTON_MODE = 'dark' | 'light' | 'neutral';
type ARROW_COLOR = undefined | 'light' | 'dark';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() size: BUTTON_SIZE = 'small';
  @Input() type: BUTTON_TYPE = 'fill';
  @Input() mode: BUTTON_MODE = 'dark';
  @Input() buttonText = '';
  @Input() href: undefined | string;
  @Input() ariaLabel = '';
  @Input() disabled = false;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter<any>();

  arrow: string = ARROW_SVG_LIGHT_PATH;

  /**
   * Returns false if the URI starts with a forward slash, which indicates an internal route.
   */
  get isExternalLink(): boolean {
    return typeof this.href === 'string' && this.href.length > 0 && !this.href.startsWith('/');
  }

  ngOnInit(): void {
    this.setArrowColor(this.mode === 'dark' ? 'light' : 'dark');
  }

  handleClick(event: Event): void {
    if (this.href !== undefined && this.href.endsWith('.pdf')) {
      event.preventDefault(); // Prevent the default action
      event.stopPropagation(); // Stop the event from propagating further
      window.open(this.href, '_blank'); // Open the PDF in a new tab
    } else {
      this.clickEvent.emit(event);
    }
  }

  setArrowColor(color: ARROW_COLOR = undefined): void {
    this.arrow = color === 'dark' ? ARROW_SVG_DARK_PATH : ARROW_SVG_LIGHT_PATH;
  }
}
