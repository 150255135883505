<ng-container *transloco="let t; read 'components.header'">
  <header [class.expanded]="isExpanded" [ngClass]="[color]">
    <div id="main-nav">
      <div class="content-group left">
        <app-nav-link (click)="handleNavItemClick()" [animated]="false" [href]="'/'" id="nav-link-logo">
          <app-hy-cite-logo color="tangerine"></app-hy-cite-logo>
        </app-nav-link>

        <app-nav-link
          #about
          (click)="handleNavItemClick(about)"
          [animated]="false"
          [dropdownPosition]="'right'"
          [isLastSelected]="lastSelection===about || lastSelection==='about' || lastSelection==='aboutMobile'"
          [linkText]='t("links.about-us")'></app-nav-link>

        <app-nav-link
          (click)="handleNavItemClick()"
          *ngFor="let link of mainLinksLeft"
          [href]="link"
          [isLastSelected]="activeRoute === link"
          [linkText]='t("links." + link)'></app-nav-link>
      </div>

      <div class="content-group right">
        <app-nav-link
          #language
          (click)="handleNavItemClick(language)"
          [animated]="false"
          [dropdownPosition]="'right'"
          [isLastSelected]="lastSelection===language"
          id="nav-link-language">
          <mat-icon>public</mat-icon>
        </app-nav-link>

        <app-nav-link
          (click)="handleNavItemClick()"
          *ngFor="let link of mainLinksRight"
          [href]="link"
          [isLastSelected]="activeRoute === link"
          [linkText]='t("links." + link)'></app-nav-link>

        <app-button (click)="handleNavItemClick()" [buttonText]='t("links.join-our-team")' [size]="'small'" href="/careers"></app-button>

        <app-hamburger
          (click)="handleHamburgerClick(about)"
          [color]="color === 'slate' ? 'light' : 'dark'"
          [isActive]="isExpanded && lastSelection !== language"></app-hamburger>
      </div>
    </div>

    <div *ngIf="isExpanded && lastSelection" class="divider horizontal"></div>
  </header>

  <ng-container *ngIf="isExpanded && lastSelection">
    <div
      *ngIf="(lastSelection === about || lastSelection === 'about' || lastSelection === 'aboutMobile'); then ((isSmallScreen$ | async) ? aboutMenuMobile : aboutMenuDesktop) else languageMenu"></div>
  </ng-container>

  <ng-template #aboutMenuMobile>
    <div class="extended-menu" id="about-mobile">
      <app-nav-link
        #aboutMobile
        (click)="handleNavItemClick('aboutMobile')"
        [dropdownPosition]="'right'"
        [isLastSelected]="lastSelection==='aboutMobile'"
        [linkText]='t("links.about-us")'></app-nav-link>

      <ng-container *ngIf="lastSelection==='aboutMobile'">
        <div (click)="ourStoryLink.handleClick(); handleNavItemClick()" class="content-group" tabindex="0">
          <a [innerHTML]='t("about-menu.title-1")' class="title link"></a>
          <p [innerHTML]='t("about-menu.subtitle-1")' class="text-small subtitle"></p>
        </div>

        <div (click)="ourPeopleLink.handleClick(); handleNavItemClick()" class="content-group" tabindex="0">
          <a [innerHTML]='t("about-menu.title-2")' class="title link"></a>
          <p [innerHTML]='t("about-menu.subtitle-2")' class="text-small subtitle"></p>
        </div>
      </ng-container>

      <app-nav-link
        (click)="handleNavItemClick()"
        *ngFor="let link of mainLinksLeft"
        [href]="link"
        [isLastSelected]="activeRoute === link"
        [linkText]='t("links." + link)'
        class="link dim"></app-nav-link>

      <app-nav-link
        (click)="handleNavItemClick()"
        *ngFor="let link of mainLinksRight"
        [href]="link"
        [isLastSelected]="activeRoute === link"
        [linkText]='t("links." + link)'
        class="link dim"></app-nav-link>

      <app-button
        (click)="handleNavItemClick()"
        [ariaLabel]="'join our team'"
        [buttonText]='t("links.join-our-team")'
        [size]="'big'"
        href="/careers"></app-button>

      <app-arrow-link #ourStoryLink (click)="handleNavItemClick()" [href]="'/our-story'" hidden="hidden"></app-arrow-link>

      <app-arrow-link #ourPeopleLink (click)="handleNavItemClick()" [href]="'/our-people'" hidden="hidden"></app-arrow-link>
    </div>
  </ng-template>

  <!-- Conditional template: about menu -->
  <ng-template #aboutMenuDesktop>
    <div class="extended-menu" id="about-desktop">
      <div (click)="ourStoryLink.handleClick(); handleNavItemClick()" class="content-group left">
        <img alt="" class="square" src="/assets/header-1.jpg" />

        <div class="inner-content-group">
          <h4 [innerHTML]='t("about-menu.title-1")' class="title"></h4>

          <p [innerHTML]='t("about-menu.subtitle-1")' class="text-small subtitle"></p>

          <div *ngFor='let text of t("about-menu.our-story-outline")' [innerHTML]="text" class="text-small subtitle outline-item"></div>

          <app-arrow-link #ourStoryLink (click)="handleNavItemClick()" [href]="'/our-story'"></app-arrow-link>
        </div>
      </div>

      <div (click)="ourPeopleLink.handleClick(); handleNavItemClick()" class="content-group right">
        <img alt="" class="square" src="/assets/header-2.jpg" />

        <div class="inner-content-group">
          <h4 [innerHTML]='t("about-menu.title-2")' class="title"></h4>

          <p [innerHTML]='t("about-menu.subtitle-2")' class="text-small subtitle"></p>

          <div *ngFor='let text of t("about-menu.our-people-outline")' [innerHTML]="text" class="text-small subtitle outline-item"></div>

          <app-arrow-link #ourPeopleLink (click)="handleNavItemClick()" [href]="'/our-people'"></app-arrow-link>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Conditional template: language selection menu -->
  <ng-template #languageMenu>
    <div class="extended-menu" id="language">
      <div class="content-group">
        <a
          (click)="handleLanguageSelection(lng); $event.preventDefault()"
          *ngFor="let lng of enabledLanguages"
          [class.active]="selectedLanguage===lng"
          [class.inactive]="selectedLanguage!==lng"
          [innerHTML]="(lng === 'es') ? 'Español' : (lng === 'pt') ? 'Português' : 'English'"
          class="link small"
          href=""></a>
      </div>
    </div>
  </ng-template>
</ng-container>
