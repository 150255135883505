import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterCTAService {
  private _footerMethodSubject: Subject<any> = new Subject<any>();

  footerMethodCalled$: Observable<any> = this._footerMethodSubject.asObservable();

  callFooterMethod(showCTA: boolean, buttonMode = ''): void {
    this._footerMethodSubject.next([showCTA, buttonMode]);
  }
}
