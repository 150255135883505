<a
  (click)="handleClick(); $event.preventDefault()"
  [class.active]="isLastSelected"
  [class.no-animate]="!animated"
  [href]="href"
  class="nav x-small">
  <mat-icon *ngIf="dropdownPosition === 'left'" class="drop-down">arrow_drop_down</mat-icon>

  <ng-content></ng-content>
  <span [innerHTML]="linkText"></span>

  <mat-icon *ngIf="dropdownPosition === 'right'" class="drop-down">arrow_drop_down</mat-icon>
</a>
