import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type HeaderColor = 'flour' | 'slate' | 'white';

@Injectable({
  providedIn: 'root'
})
export class HeaderColorService {
  private _headerMethodSubject: Subject<HeaderColor> = new Subject<HeaderColor>();

  headerMethodCalled$: Observable<HeaderColor> = this._headerMethodSubject.asObservable();

  callHeaderMethod(color: HeaderColor): void {
    this._headerMethodSubject.next(color);
  }
}
