<ng-container *ngIf="isExternalLink; else internalLink">
  <a
    (click)="handleClick($event)"
    [attr.aria-label]="ariaLabel"
    [href]="href"
    [ngClass]="['button', 'no-animate', size, type, mode, disabled ? 'disabled-link' : '']"
    [attr.disabled]="disabled ? true : null"
    rel="noopener noreferrer"
    target="_blank"
    type="button">
    <div [innerHTML]="buttonText" class="button-text"></div>
    <img [src]="arrow" alt="" aria-hidden="true" class="arrow" />
  </a>
</ng-container>

<ng-template #internalLink>
  <a
    (click)="handleClick($event)"
    [attr.aria-label]="ariaLabel"
    [href]="href?.endsWith('.pdf') ? 'javascript:void(0)' : href"
    [ngClass]="['button', 'no-animate', size, type, mode, disabled ? 'disabled-link' : '']"
    [attr.disabled]="disabled ? true : null"
    [routerLink]="href?.endsWith('.pdf') ? undefined : href"
    type="button">
    <div [innerHTML]="buttonText" class="button-text"></div>
    <img [src]="arrow" alt="" aria-hidden="true" class="arrow" />
  </a>
</ng-template>
