<div *transloco="let t" [class.no-cta]="!showCTA" id="footer-component">
  <div *ngIf="showCTA" class="inner-page" id="lets-work-together-cta">
    <div class="content">
      <div class="content-group basic-content-with-subtitle">
        <h2 [innerHTML]="t('components.footer.title')" class="title"></h2>
        <p [innerHTML]="t('components.footer.subtitle')" class="text-med subtitle"></p>
        <app-button
          [buttonText]="CTAButtonText ?? t('components.footer.button')"
          [href]="CTAButtonHref ?? '/careers'"
          mode="light"
          size="big"
          type="outline"></app-button>
      </div>
    </div>

    <div id="cta-text-footer">
      <div class="content">
        <div
          #text1
          [class.active]="text1InView && text2InView"
          [innerHTML]="t('components.footer.animated-titles')[0]"
          class="h1 title animated-footer-title"
          id="text1"></div>
        <div
          #text2
          [class.active]="text1InView && text2InView"
          [innerHTML]="t('components.footer.animated-titles')[1]"
          class="h1 title animated-footer-title"
          id="text2"></div>
      </div>
    </div>
  </div>

  <div class="inner-page" id="footer-links-and-resources">
    <div class="content">
      <div class="content-group top">
        <a class="no-animate" href="/">
          <app-hy-cite-logo [withWords]="true" color="tangerine"></app-hy-cite-logo>
        </a>
      </div>

      <div class="content-group bottom desktop-only">
        <div class="grid-area site-nav">
          <a *ngFor="let link of t('components.footer.links.routes').slice(0, 4)" [href]="link.uri" [innerHTML]="link.name" class="med"></a>
        </div>

        <div class="grid-area site-nav">
          <a *ngFor="let link of t('components.footer.links.routes').slice(4)" [href]="link.uri" [innerHTML]="link.name" class="med"></a>
        </div>

        <div class="grid-area site-nav">
          <a
            *ngFor="let link of t('components.footer.links.legal')"
            [href]="link.uri"
            [innerHTML]="link.name"
            class="tos-privacy-link small"></a>
        </div>

        <div class="grid-area headquarters-social">
          <div class="headquarters">
            <div [innerHTML]="t('components.footer.headquarters')" class="h6 headquarters-title subtitle"></div>
            <p [innerHTML]="headquartersAddress" class="text-med headquarters-address"></p>
          </div>

          <div class="grid-area social-links">
            <a *ngFor="let link of socialLinks" [href]="link.url" class="social-link no-animate" target="_blank">
              <img [alt]="link.altText" [src]="link['img-uri']" />
            </a>
          </div>
        </div>

        <div class="grid-area copyright">
          <p [innerHTML]="copyrightText" class="text-small" id="copyright"></p>
        </div>

        <div class="grid-area cookie-settings">
          <!-- OneTrust Cookies Settings button start -->
          <button class="ot-sdk-show-settings" id="ot-sdk-btn" style="max-width: 200px">Cookie Settings</button>
          <!-- OneTrust Cookies Settings button end -->
        </div>

        <div class="grid-area tos-privacy">
          <a
            *ngFor="let tos of t('components.footer.links.tos-privacy')"
            [href]="tos.uri"
            [innerHTML]="tos.name"
            class="tos-privacy-link small"></a>
        </div>
      </div>

      <div class="content-group bottom mobile-only" hidden="hidden">
        <div class="grid-area cta-titles">
          <div [innerHTML]="t('components.footer.animated-titles')[0]" class="h3 title animated-footer-title"></div>
          <div [innerHTML]="t('components.footer.animated-titles')[1]" class="h3 title animated-footer-title"></div>
        </div>

        <div class="grid-area headquarters">
          <div [innerHTML]="t('components.footer.headquarters')" class="h6 headquarters-title subtitle"></div>
          <p [innerHTML]="headquartersAddress" class="text-small headquarters-address"></p>
        </div>

        <div class="grid-area site-nav">
          <a *ngFor="let link of t('components.footer.links.routes')" [href]="link.uri" [innerHTML]="link.name" class="med"></a>
        </div>

        <div class="grid-area social-links">
          <a *ngFor="let link of socialLinks" [href]="link.url" class="social-link no-animate" target="_blank">
            <img [alt]="link.altText" [src]="link['img-uri']" />
          </a>
        </div>

        <div class="grid-area tos-privacy">
          <a
            *ngFor="let link of t('components.footer.links.legal')"
            [href]="link.uri"
            [innerHTML]="link.name"
            class="tos-privacy-link small"></a>
          <a
            *ngFor="let tos of t('components.footer.links.tos-privacy')"
            [href]="tos.uri"
            [innerHTML]="tos.name"
            class="tos-privacy-link small"></a>
        </div>

        <div class="grid-area cookie-settings">
          <!-- OneTrust Cookies Settings button start -->
          <button class="ot-sdk-show-settings" id="ot-sdk-btn" style="max-width: 200px">Cookie Settings</button>
          <!-- OneTrust Cookies Settings button end -->
        </div>

        <div class="grid-area copyright">
          <p [innerHTML]="copyrightText" class="text-small"></p>
        </div>
      </div>
    </div>
  </div>
</div>
