import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent {
  @Input() href = '';
  @Input() linkText = '';
  @Input() animated = true;
  @Input() isLastSelected = false;
  @Input() dropdownPosition: undefined | 'left' | 'right';

  constructor(private router: Router) {}

  handleClick(): void {
    if (this.dropdownPosition) {
      this.isLastSelected = !this.isLastSelected;
      return;
    }

    if (this.href) this.router.navigate([this.href]).then();
  }
}
