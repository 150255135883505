import { CountriesLocales } from '../types';
/*
 * What is this file?
 * A hash of countries with their official locales (e.g official languages spoken per country)
 *
 * */

// tslint:disable-next-line:variable-name
const _countries: { [key: string]: CountriesLocales } = {
  af: { name: 'Afghanistan', locales: ['ps-AF', 'fa-AF'] },
  al: { name: 'Albania', locales: ['sq-AL'] },
  dz: { name: 'Algeria', locales: ['ar-DZ'] },
  as: { name: 'American Samoa', locales: ['en-AS'] },
  ad: { name: 'Andorra', locales: ['ca-AD'] },
  ao: { name: 'Angola', locales: ['pt-AO'] },
  ai: { name: 'Anguilla', locales: ['en-AI'] },
  aq: { name: 'Antarctica', locales: ['en-US'] },
  ag: { name: 'Antigua and Barbuda', locales: ['en-AG'] },
  ar: { name: 'Argentina', locales: ['es-AR'] },
  am: { name: 'Armenia', locales: ['hy-AM'] },
  aw: { name: 'Aruba', locales: ['nl-AW'] },
  au: { name: 'Australia', locales: ['en-AU'] },
  at: { name: 'Austria', locales: ['de-AT'] },
  az: { name: 'Azerbaijan', locales: ['az-AZ'] },
  bs: { name: 'Bahamas', locales: ['en-BS'] },
  bh: { name: 'Bahrain', locales: ['ar-BH'] },
  bd: { name: 'Bangladesh', locales: ['bn-BD'] },
  bb: { name: 'Barbados', locales: ['en-BB'] },
  by: { name: 'Belarus', locales: ['be-BY'] },
  be: { name: 'Belgium', locales: ['nl-BE', 'en-BE', 'fr-BE', 'de-BE'] },
  bz: { name: 'Belize', locales: ['en-BZ'] },
  bj: { name: 'Benin', locales: ['fr-BJ'] },
  bm: { name: 'Bermuda', locales: ['en-BM'] },
  bt: { name: 'Bhutan', locales: ['dz-BT'] },
  bo: { name: 'Bolivia', locales: ['es-BO'] },
  bq: { name: 'Bonaire', locales: ['nl-BQ'] },
  ba: { name: 'Bosnia and Herzegovina', locales: ['bs-BA'] },
  bw: { name: 'Botswana', locales: ['en-BW'] },
  bv: { name: 'Bouvet Island', locales: ['no-BV'] },
  br: { name: 'Brazil', locales: ['pt-BR'] },
  io: { name: 'British Indian Ocean Territory', locales: ['en-IO'] },
  bn: { name: 'Brunei Darussalam', locales: ['ms-BN'] },
  bg: { name: 'Bulgaria', locales: ['bg-BG'] },
  bf: { name: 'Burkina Faso', locales: ['fr-BF'] },
  bi: { name: 'Burundi', locales: ['fr-BI'] },
  cv: { name: 'Cabo Verde', locales: ['pt-CV'] },
  kh: { name: 'Cambodia', locales: ['km-KH'] },
  cm: { name: 'Cameroon', locales: ['fr-CM'] },
  ca: { name: 'Canada', locales: ['en-CA', 'fr-CA'] },
  ky: { name: 'Cayman Islands', locales: ['en-KY'] },
  cf: { name: 'Central African Republic', locales: ['fr-CF', 'sg-CF'] },
  td: { name: 'Chad', locales: ['fr-TD'] },
  cl: { name: 'Chile', locales: ['es-CL'] },
  cn: { name: 'China', locales: ['zh-CN', 'ii-CN', 'bo-CN'] },
  cx: { name: 'Christmas Island', locales: ['en-CX'] },
  cc: { name: 'Cocos Islands', locales: ['en-CC'] },
  co: { name: 'Colombia', locales: ['es-CO'] },
  km: { name: 'Comoros', locales: ['fr-KM'] },
  cd: { name: 'Democratic Republic of the Congo', locales: ['fr-CD'] },
  cg: { name: 'Congo', locales: ['fr-CG'] },
  ck: { name: 'Cook Islands', locales: ['en-CK'] },
  cr: { name: 'Costa Rica', locales: ['es-CR'] },
  hr: { name: 'Croatia', locales: ['hr-HR'] },
  cu: { name: 'Cuba', locales: ['es-CU'] },
  cw: { name: 'Curaçao', locales: ['nl-CW'] },
  cy: { name: 'Cyprus', locales: ['el-CY'] },
  cz: { name: 'Czechia', locales: ['cs-CZ'] },
  ci: { name: "Côte d'Ivoire", locales: ['fr-CI'] },
  dk: { name: 'Denmark', locales: ['da-DK'] },
  dj: { name: 'Djibouti', locales: ['fr-DJ', 'so-DJ'] },
  dm: { name: 'Dominica', locales: ['en-DM'] },
  do: { name: 'Dominican Republic', locales: ['es-DO'] },
  ec: { name: 'Ecuador', locales: ['es-EC'] },
  eg: { name: 'Egypt', locales: ['ar-EG'] },
  sv: { name: 'El Salvador', locales: ['es-SV'] },
  gq: { name: 'Equatorial Guinea', locales: ['fr-GQ', 'es-GQ'] },
  er: { name: 'Eritrea', locales: ['ti-ER'] },
  ee: { name: 'Estonia', locales: ['et-EE'] },
  sz: { name: 'Eswatini', locales: ['en-SZ'] },
  et: { name: 'Ethiopia', locales: ['am-ET', 'om-ET', 'so-ET', 'ti-ET'] },
  fk: { name: 'Falkland Islands', locales: ['en-FK'] },
  fo: { name: 'Faroe Islands', locales: ['fo-FO'] },
  fj: { name: 'Fiji', locales: ['en-FJ'] },
  fi: { name: 'Finland', locales: ['fi-FI', 'sv-FI'] },
  fr: { name: 'France', locales: ['fr-FR'] },
  gf: { name: 'French Guiana', locales: ['fr-GF'] },
  pf: { name: 'French Polynesia', locales: ['fr-PF'] },
  tf: { name: 'French Southern Territories', locales: ['fr-TF'] },
  ga: { name: 'Gabon', locales: ['fr-GA'] },
  gm: { name: 'Gambia', locales: ['en-GM'] },
  ge: { name: 'Georgia', locales: ['ka-GE'] },
  de: { name: 'Germany', locales: ['de-DE'] },
  gh: { name: 'Ghana', locales: ['ak-GH', 'ee-GH'] },
  gi: { name: 'Gibraltar', locales: ['en-GI'] },
  gr: { name: 'Greece', locales: ['el-GR'] },
  gl: { name: 'Greenland', locales: ['kl-GL'] },
  gd: { name: 'Grenada', locales: ['en-GD'] },
  gp: { name: 'Guadeloupe', locales: ['fr-GP'] },
  gu: { name: 'Guam', locales: ['en-GU'] },
  gt: { name: 'Guatemala', locales: ['es-GT'] },
  gg: { name: 'Guernsey', locales: ['en-GG'] },
  gn: { name: 'Guinea', locales: ['fr-GN'] },
  gw: { name: 'Guinea-Bissau', locales: ['pt-GW'] },
  gy: { name: 'Guyana', locales: ['en-GY'] },
  ht: { name: 'Haiti', locales: ['fr-HT'] },
  hm: { name: 'Heard Island and McDonald Islands', locales: ['en-HM'] },
  va: { name: 'Holy See', locales: ['it-VA'] },
  hn: { name: 'Honduras', locales: ['es-HN'] },
  hk: { name: 'Hong Kong', locales: ['en-HK'] },
  hu: { name: 'Hungary', locales: ['hu-HU'] },
  is: { name: 'Iceland', locales: ['is-IS'] },
  in: {
    name: 'India',
    locales: ['as-IN', 'bn-IN', 'en-IN', 'gu-IN', 'hi-IN', 'kn-IN', 'ml-IN', 'mr-IN', 'ne-IN', 'or-IN', 'ta-IN', 'te-IN', 'bo-IN', 'ur-IN']
  },
  id: { name: 'Indonesia', locales: ['id-ID'] },
  ir: { name: 'Iran', locales: ['fa-IR'] },
  iq: { name: 'Iraq', locales: ['ar-IQ'] },
  ie: { name: 'Ireland', locales: ['en-IE', 'ga-IE'] },
  im: { name: 'Isle of Man', locales: ['en-IM'] },
  il: { name: 'Israel', locales: ['en-IL', 'he-IL'] },
  it: { name: 'Italy', locales: ['it-IT'] },
  jm: { name: 'Jamaica', locales: ['en-JM'] },
  jp: { name: 'Japan', locales: ['ja-JP'] },
  je: { name: 'Jersey', locales: ['en-JE'] },
  jo: { name: 'Jordan', locales: ['ar-JO'] },
  kz: { name: 'Kazakhstan', locales: ['kk-KZ'] },
  ke: { name: 'Kenya', locales: ['ki-KE', 'om-KE', 'so-KE', 'sw-KE'] },
  ki: { name: 'Kiribati', locales: ['en-KI'] },
  kp: { name: 'North Korea', locales: ['ko-KP'] },
  kr: { name: 'South Korea', locales: ['ko-KR'] },
  kw: { name: 'Kuwait', locales: ['ar-KW'] },
  kg: { name: 'Kyrgyzstan', locales: ['ky-KG'] },
  la: { name: "Lao People's Democratic Republic", locales: ['lo-LA'] },
  lv: { name: 'Latvia', locales: ['lv-LV'] },
  lb: { name: 'Lebanon', locales: ['ar-LB'] },
  ls: { name: 'Lesotho', locales: ['en-LS'] },
  lr: { name: 'Liberia', locales: ['en-LR'] },
  ly: { name: 'Libya', locales: ['ar-LY'] },
  li: { name: 'Liechtenstein', locales: ['de-LI'] },
  lt: { name: 'Lithuania', locales: ['lt-LT'] },
  lu: { name: 'Luxembourg', locales: ['fr-LU', 'de-LU'] },
  mg: { name: 'Madagascar', locales: ['fr-MG', 'mg-MG'] },
  mw: { name: 'Malawi', locales: ['en-MW'] },
  my: { name: 'Malaysia', locales: ['ms-MY'] },
  mv: { name: 'Maldives', locales: ['dv-MV'] },
  ml: { name: 'Mali', locales: ['bm-ML', 'fr-ML'] },
  mt: { name: 'Malta', locales: ['en-MT', 'mt-MT'] },
  mh: { name: 'Marshall Islands', locales: ['en-MH'] },
  mq: { name: 'Martinique', locales: ['fr-MQ'] },
  mr: { name: 'Mauritania', locales: ['ar-MR'] },
  mu: { name: 'Mauritius', locales: ['en-MU'] },
  yt: { name: 'Mayotte', locales: ['fr-YT'] },
  mx: { name: 'Mexico', locales: ['es-MX'] },
  fm: { name: 'Micronesia', locales: ['en-FM'] },
  md: { name: 'Moldova', locales: ['ro-MD', 'ru-MD'] },
  mc: { name: 'Monaco', locales: ['fr-MC'] },
  mn: { name: 'Mongolia', locales: ['mn-MN'] },
  me: { name: 'Montenegro', locales: ['sr-ME'] },
  ms: { name: 'Montserrat', locales: ['en-MS'] },
  ma: { name: 'Morocco', locales: ['ar-MA'] },
  mz: { name: 'Mozambique', locales: ['pt-MZ'] },
  mm: { name: 'Myanmar', locales: ['my-MM'] },
  na: { name: 'Namibia', locales: ['af-NA', 'en-NA'] },
  nr: { name: 'Nauru', locales: ['en-NR'] },
  np: { name: 'Nepal', locales: ['ne-NP'] },
  nl: { name: 'Netherlands', locales: ['nl-NL'] },
  nc: { name: 'New Caledonia', locales: ['fr-NC'] },
  nz: { name: 'New Zealand', locales: ['en-NZ'] },
  ni: { name: 'Nicaragua', locales: ['es-NI'] },
  ne: { name: 'Niger', locales: ['fr-NE'] },
  ng: { name: 'Nigeria', locales: ['ig-NG', 'yo-NG'] },
  nu: { name: 'Niue', locales: ['en-NU'] },
  nf: { name: 'Norfolk Island', locales: ['en-NF'] },
  mk: { name: 'North Macedonia', locales: ['mk-MK'] },
  mp: { name: 'Northern Mariana Islands', locales: ['en-MP'] },
  no: { name: 'Norway', locales: ['nb-NO', 'nn-NO'] },
  om: { name: 'Oman', locales: ['ar-OM'] },
  pk: { name: 'Pakistan', locales: ['en-PK', 'ur-PK'] },
  pw: { name: 'Palau', locales: ['en-PW'] },
  ps: { name: 'Palestine', locales: ['ar-PS'] },
  pa: { name: 'Panama', locales: ['es-PA'] },
  pg: { name: 'Papua New Guinea', locales: ['en-PG'] },
  py: { name: 'Paraguay', locales: ['es-PY'] },
  pe: { name: 'Peru', locales: ['es-PE'] },
  ph: { name: 'Philippines', locales: ['en-PH'] },
  pn: { name: 'Pitcairn', locales: ['en-PN'] },
  pl: { name: 'Poland', locales: ['pl-PL'] },
  pt: { name: 'Portugal', locales: ['pt-PT'] },
  pr: { name: 'Puerto Rico', locales: ['es-PR'] },
  qa: { name: 'Qatar', locales: ['ar-QA'] },
  ro: { name: 'Romania', locales: ['ro-RO'] },
  ru: { name: 'Russia', locales: ['ru-RU'] },
  rw: { name: 'Rwanda', locales: ['fr-RW', 'rw-RW'] },
  re: { name: 'Réunion', locales: ['fr-RE'] },
  bl: { name: 'Saint Barthélemy', locales: ['fr-BL'] },
  sh: { name: 'Saint Helena', locales: ['en-SH'] },
  kn: { name: 'Saint Kitts and Nevis', locales: ['en-KN'] },
  lc: { name: 'Saint Lucia', locales: ['en-LC'] },
  mf: { name: 'Saint Martin', locales: ['fr-MF'] },
  pm: { name: 'Saint Pierre and Miquelon', locales: ['fr-PM'] },
  vc: { name: 'Saint Vincent and the Grenadines', locales: ['en-VC'] },
  ws: { name: 'Samoa', locales: ['sm-WS'] },
  sm: { name: 'San Marino', locales: ['it-SM'] },
  st: { name: 'Sao Tome and Principe', locales: ['pt-ST'] },
  sa: { name: 'Saudi Arabia', locales: ['ar-SA'] },
  sn: { name: 'Senegal', locales: ['fr-SN', 'ff-SN'] },
  rs: { name: 'Serbia', locales: ['sr-RS'] },
  sc: { name: 'Seychelles', locales: ['fr-SC'] },
  sl: { name: 'Sierra Leone', locales: ['en-SL'] },
  sg: { name: 'Singapore', locales: ['en-SG'] },
  sx: { name: 'Sint Maarten', locales: ['nl-SX'] },
  sk: { name: 'Slovakia', locales: ['sk-SK'] },
  si: { name: 'Slovenia', locales: ['sl-SI'] },
  sb: { name: 'Solomon Islands', locales: ['en-SB'] },
  so: { name: 'Somalia', locales: ['so-SO'] },
  za: { name: 'South Africa', locales: ['af-ZA', 'en-ZA', 'zu-ZA'] },
  gs: { name: 'South Georgia and the South Sandwich Islands', locales: ['en-GS'] },
  ss: { name: 'South Sudan', locales: ['en-SS'] },
  es: { name: 'Spain', locales: ['eu-ES', 'ca-ES', 'gl-ES', 'es-ES'] },
  lk: { name: 'Sri Lanka', locales: ['si-LK', 'ta-LK'] },
  sd: { name: 'Sudan', locales: ['ar-SD'] },
  sr: { name: 'Suriname', locales: ['nl-SR'] },
  sj: { name: 'Svalbard and Jan Mayen', locales: ['no-SJ'] },
  se: { name: 'Sweden', locales: ['sv-SE'] },
  ch: { name: 'Switzerland', locales: ['de-CH', 'fr-CH', 'it-CH', 'rm-CH'] },
  sy: { name: 'Syrian Arab Republic', locales: ['ar-SY'] },
  tw: { name: 'Taiwan', locales: ['zh-TW', 'en-TW'] },
  tj: { name: 'Tajikistan', locales: ['tg-TJ'] },
  tz: { name: 'Tanzania', locales: ['sw-TZ'] },
  th: { name: 'Thailand', locales: ['th-TH'] },
  tl: { name: 'Timor-Leste', locales: ['pt-TL'] },
  tg: { name: 'Togo', locales: ['ee-TG', 'fr-TG'] },
  tk: { name: 'Tokelau', locales: ['en-TK'] },
  to: { name: 'Tonga', locales: ['to-TO'] },
  tt: { name: 'Trinidad and Tobago', locales: ['en-TT'] },
  tn: { name: 'Tunisia', locales: ['ar-TN'] },
  tr: { name: 'Turkey', locales: ['tr-TR'] },
  tm: { name: 'Turkmenistan', locales: ['tk-TM'] },
  tc: { name: 'Turks and Caicos Islands', locales: ['en-TC'] },
  tv: { name: 'Tuvalu', locales: ['en-TV'] },
  ug: { name: 'Uganda', locales: ['lg-UG'] },
  ua: { name: 'Ukraine', locales: ['ru-UA', 'uk-UA'] },
  ae: { name: 'United Arab Emirates', locales: ['ar-AE'] },
  gb: { name: 'United Kingdom', locales: ['kw-GB', 'en-GB', 'gv-GB', 'cy-GB'] },
  um: { name: 'United States Minor Outlying Islands', locales: ['en-UM'] },
  us: { name: 'United States', locales: ['en-US', 'es-US'] },
  uy: { name: 'Uruguay', locales: ['es-UY'] },
  uz: { name: 'Uzbekistan', locales: ['uz-UZ'] },
  vu: { name: 'Vanuatu', locales: ['bi-VU'] },
  ve: { name: 'Venezuela', locales: ['es-VE'] },
  vn: { name: 'Viet Nam', locales: ['vi-VN'] },
  vg: { name: 'Virgin Islands (British)', locales: ['en-VG'] },
  vi: { name: 'Virgin Islands (U.S.)', locales: ['en-VI'] },
  wf: { name: 'Wallis and Futuna', locales: ['fr-WF'] },
  eh: { name: 'Western Sahara', locales: ['es-EH'] },
  ye: { name: 'Yemen', locales: ['ar-YE'] },
  zm: { name: 'Zambia', locales: ['en-ZM'] },
  zw: { name: 'Zimbabwe', locales: ['en-ZW', 'nd-ZW', 'sn-ZW'] },
  ax: { name: 'Aland Islands', locales: ['sv-AX'] }
};
export const countries = new Map<string, CountriesLocales>(Object.keys(_countries).map((k: string) => [k, _countries[k]])) as ReadonlyMap<
  string,
  CountriesLocales
>;
