import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { GraphQLModule } from './graphql.module';
import { HtmlPipe, ReplacePipe, SafePipe } from './utils/safe.pipe';
import { HttpClientModule } from '@angular/common/http';
import { KeysPipe } from './utils/keys.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoRootModule } from './transloco-root.module';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { OneTrustModule, OneTrustService } from './components/onetrust';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    GraphQLModule,
    HttpClientModule,
    LayoutModule,
    MatIconModule,
    TranslocoRootModule,
    HeaderModule,
    FooterModule,
    Angulartics2Module.forRoot({
      gst: {
        trackingIds: ['GTM-NBRSP2HN'],
        // customMap: {
        //   dimension1: 'version',
        //   dimension2: 'page_language',
        //   dimension3: 'custom_dimension_name'
        // },
        anonymizeIp: true
      }
    }),
    OneTrustModule.forRoot({
      cookiesGroups: {
        StrictlyNecessaryCookies: 'C0001',
        PerformanceCookies: 'C0002',
        FunctionalCookies: 'C0003',
        TargetingCookies: 'C0004',
        SocialMediaCookies: 'C0005'
      },
      domainScript: '39edd0b0-d20a-4515-8792-29e483d4bc83-test'
    })
  ],
  providers: [
    KeysPipe,
    SafePipe,
    HtmlPipe,
    ReplacePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (oneTrustService: OneTrustService) => async () => {
        oneTrustService.loadOneTrust();
      },
      multi: true,
      deps: [OneTrustService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
